
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src: local(''),
	url('../../assets/fonts/roboto/roboto-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('../../assets/fonts/roboto/roboto-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
