@use "../variables" as *;

.mat-mdc-raised-button {
  &.mat-primary {
    --mdc-protected-button-label-text-color: #{$primary-contrast};
  }
  
  &.mat-accent {
    --mdc-protected-button-label-text-color: #{$accent-contrast};
  }
}


.mat-mdc-fab {
  
  &.mat-primary {
    --mdc-fab-icon-color: #{$primary-contrast};
    --mat-mdc-fab-color: #{$primary-contrast};
  }
  
  &.mat-accent {
    --mdc-fab-icon-color: #{$accent-contrast};
    --mat-mdc-fab-color: #{$accent-contrast};
  }
}

.mat-mdc-progress-spinner {
  &.mat-white {
    --mdc-circular-progress-active-indicator-color: white;
  }
  
  &.mat-success {
    --mdc-circular-progress-active-indicator-color: #{$success};
  }
}
