
.ngx-timer-circle {
	--timer-color: currentcolor;
	--timer-background: #F4F4F4;
	--timer-inset: 0px;
	--timer-width: 8px;
	position: relative;
	
	> svg {
		position: absolute;
		left: var(--timer-inset);
		top: var(--timer-inset);
		width: calc(100% - (2 * var(--timer-inset)));
		height: calc(100% - (2 * var(--timer-inset)));
		
		circle.progress {
			fill: transparent;
			stroke-linecap: round;
			stroke: var(--timer-color);
			stroke-width: var(--timer-width);
			stroke-dashoffset: 0;
		}
		
		circle.background {
			fill: transparent;
			stroke: var(--timer-background);
			stroke-width: var(--timer-width);
			stroke-dashoffset: 0;
		}
	}
}
