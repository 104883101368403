
@use "../variables" as *;

// Variables
$header-background: #FFFFFF;
$sub-header-background: #FCFCFC;
$tab-header-background: #FFFFFF;
$sub-tab-header-background: #F8F8F8;
$cms-header-height: 60px;
$cms-sub-header-height: 60px;
$cms-tab-header-height: 50px;
$cms-sub-tab-header-height: 55px;

.ngx-ui-header {
  position: sticky;
  top: 0;
  border-bottom: thin solid $text-color-100;

  &.main-header {
    padding: 20px;
    background-color: $header-background;
    height: $cms-header-height;
    z-index: 12;

    .title {
      font-size: 34px;
    }
  }

  &.sub-header {
    padding: 8px 20px;
    background-color: $sub-header-background;
    height: $cms-sub-header-height;
    z-index: 11;

    .title {
      font-size: 20px;
    }
  }
  
  &.main-tab-header {
    background-color: $tab-header-background;
    height: $cms-tab-header-height;
    z-index: 11;
  }
  
  &.tab-header {
    background-color: $tab-header-background;
    height: $cms-tab-header-height;
    z-index: 11;
  }

  &.sub-tab-header {
    padding: 5px 20px;
    background-color: $sub-header-background;
    height: $cms-sub-tab-header-height;
    z-index: 10;

    .title {
      font-size: 18px;
    }
  }
  
  &.sub-main-tab-header {
    padding: 5px 20px;
    background-color: $sub-header-background;
    height: $cms-sub-tab-header-height;
    z-index: 10;
    
    .title {
      font-size: 18px;
    }
  }
}

.ngx-ui-scope {
  overflow: hidden;
  position: relative;

  &.no-header {
    height: 100%;
  }

  &.main-content {
    height: calc(100% - #{$cms-header-height});
  }

  &.sub-content {
    height: calc(100% - #{$cms-sub-header-height});
  }

  &.tab-content {
    height: calc(100% - #{$cms-tab-header-height});
  }

  &.main-tab-content {
    height: calc(100% - #{$cms-tab-header-height});
  }

  &.sub-tab-content {
    height: calc(100% - #{$cms-sub-tab-header-height});
  }

  &.sub-main-tab-content {
    height: calc(100% - #{$cms-sub-tab-header-height});
  }

  &.no-children {
    overflow-x: hidden;
    overflow-y: auto;
    touch-action: unset;
    overscroll-behavior: unset;

    @include default-scroll-bar;

    html.no-scroll & {
      // Ignore touch scroll event
      touch-action: none;
      // Hide overflow
      overflow: hidden;
      // Remove "scroll bounce"
      overscroll-behavior: none;
    }

    html.no-scroll .ngx-overlay & {
      overflow-x: hidden;
      overflow-y: auto;
      touch-action: unset;
      overscroll-behavior: unset;
    }
  }

  > cms-nav-table, > cms-nav-grid, > cms-nav-list, > cms-nav-record {
    &:first-child {
      min-height: 100%;
    }
  }

  > cms-selection-table, > cms-selection-grid, > cms-selection-list, > cms-selection-record {
    &:first-child {
      min-height: 100%;
    }
  }
}
