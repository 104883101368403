@use "../variables" as *;

// Make MatRipple clickable
.mat-ripple {
	cursor: pointer;
}

// Menu Buttons
.mat-mdc-menu-item {
	
	> i {
		font-size: 24px;
		height: 24px;
		width: 24px;
		margin-right: 16px;
		vertical-align: middle;
		color: $text-color-300;
	}
}
