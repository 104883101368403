@use "../variables" as *;

nav.mat-mdc-tab-nav-bar {
	&.leaderboard {
		border: none;
		background-color: rgba(black, 0.02);
		
		.mat-mdc-tab-link {
			font-weight: bold;
			opacity: 1;
			padding: 0 10px;
			margin: 0 10px;
			min-width: 40px;
			height: 40px;
			
			&.mdc-tab--active {
				color: $accent;
			}
		}
		
		&.mat-mdc-tab-header {
			--mdc-tab-indicator-active-indicator-height: 3px
		}
	}
	
	&.agenda {
		border: none;
		
		.mat-mdc-tab-link {
			font-weight: bold;
			opacity: 1;
			padding: 0 10px;
			margin: 0 10px;
			min-width: 40px;
			height: 40px;
			color: $text-color-400;
			
			&.mdc-tab--active {
				color: $primary;
			}
		}
		
		&.mat-mdc-tab-header {
			--mdc-tab-indicator-active-indicator-height: 3px
		}
	}
}
