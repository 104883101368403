
:root {
	--theme-primary-50: #e9ebec;
	--theme-primary-100: #c7ced1;
	--theme-primary-200: #a2adb2;
	--theme-primary-300: #7d8c93;
	--theme-primary-400: #61737b;
	--theme-primary-500: #455a64;
	--theme-primary-600: #3e525c;
	--theme-primary-700: #364852;
	--theme-primary-800: #2e3f48;
	--theme-primary-900: #1f2e36;
	--theme-primary-A100: #7dcdff;
	--theme-primary-A200: #4abaff;
	--theme-primary-A400: #17a7ff;
	--theme-primary-A700: #009cfc;
	--theme-primary-contrast-50: #000000;
	--theme-primary-contrast-100: #000000;
	--theme-primary-contrast-200: #000000;
	--theme-primary-contrast-300: #000000;
	--theme-primary-contrast-400: #ffffff;
	--theme-primary-contrast-500: #ffffff;
	--theme-primary-contrast-600: #ffffff;
	--theme-primary-contrast-700: #ffffff;
	--theme-primary-contrast-800: #ffffff;
	--theme-primary-contrast-900: #ffffff;
	--theme-primary-contrast-A100: #000000;
	--theme-primary-contrast-A200: #000000;
	--theme-primary-contrast-A400: #000000;
	--theme-primary-contrast-A700: #ffffff;
	
	--theme-accent-50: #f1eeec;
	--theme-accent-100: #ddd4d0;
	--theme-accent-200: #c6b7b1;
	--theme-accent-300: #af9a92;
	--theme-accent-400: #9e847a;
	--theme-accent-500: #8d6e63;
	--theme-accent-600: #85665b;
	--theme-accent-700: #7a5b51;
	--theme-accent-800: #705147;
	--theme-accent-900: #5d3f35;
	--theme-accent-A100: #ffc1ae;
	--theme-accent-A200: #ff9a7b;
	--theme-accent-A400: #ff7248;
	--theme-accent-A700: #ff5f2f;
	--theme-accent-contrast-50: #000000;
	--theme-accent-contrast-100: #000000;
	--theme-accent-contrast-200: #000000;
	--theme-accent-contrast-300: #000000;
	--theme-accent-contrast-400: #000000;
	--theme-accent-contrast-500: #ffffff;
	--theme-accent-contrast-600: #ffffff;
	--theme-accent-contrast-700: #ffffff;
	--theme-accent-contrast-800: #ffffff;
	--theme-accent-contrast-900: #ffffff;
	--theme-accent-contrast-A100: #000000;
	--theme-accent-contrast-A200: #000000;
	--theme-accent-contrast-A400: #000000;
	--theme-accent-contrast-A700: #000000;
}
