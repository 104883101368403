@use "../variables" as *;

html {
	overflow-y: auto;
	overflow-x: hidden;
	font-family: $font-stack;
	color: $text-color;
	background-color: $background;
	height: 100%;
	transition: background-color 100ms linear, color 100ms linear;
	
	@include default-scroll-bar();
	
	body {
		height: 100%;
	}
	
	//noinspection CssInvalidPropertyValue
	&.no-scroll {
		// Preventing body scroll for modals in iOS
		// https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
		touch-action: none;
		overflow-scrolling: none;
		-webkit-overflow-scrolling: none;
		overflow: hidden;
		overscroll-behavior: none;
	}
}
