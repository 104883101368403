@use "../variables" as *;

.base-card {
	box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
	background: $card-gradiant;
	display: flex;
	flex-flow: row wrap;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 24px;
	position: relative;
	margin-bottom: 20px;
	
	.row {
		position: relative;
		display: flex;
		flex-flow: row;
		align-items: center;
		flex-grow: 1;
		min-width: 0;
		
		img {
			height: 64px;
			width: 64px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 24px;
			flex-shrink: 0;
			object-fit: cover;
		}
		
		.icon {
			height: 64px;
			width: 64px;
			text-align: center;
			line-height: 64px;
			font-size: 40px;
			color: $accent;
			margin-right: 24px;
		}
		
		i.flag {
			position: absolute;
			top: -5px;
			left: -5px;
			width: 24px;
			height: 24px;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
		}
	}
	
	
	.info {
		flex-grow: 1;
		flex-shrink: 1;
		padding-right: 10px;
		display: flex;
		flex-flow: column;
		align-items: stretch;
		min-width: 0;
		
		p:first-child {
			font-size: 16px;
			line-height: 24px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		
		p:nth-child(2) {
			margin-top: 4px;
			text-transform: uppercase;
		}
		
		.description {
			font-size: 14px;
			line-height: 1.5;
			word-break: break-word;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			text-transform: none !important;
			color: $text-color-400;
		}
	}
	
	.actions {
		display: flex;
		flex-flow: row;
		align-items: center;
	}
	
	.clear {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 32px;
		height: 32px;
		line-height: 32px;
		font-size: 24px;
		text-align: center;
		border-radius: 4px;
	}
}
