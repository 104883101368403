@use "../variables" as *;

//<editor-fold desc="UI Scope">
$header-height: 54px;
$overlay-header-height: 54px;

.ngx-ui-header {
	&.moderation-header {
		height: $header-height;
		background-color: $primary;
		color: $primary-contrast;
		padding: 3px 10px;
		z-index: 12;
	}
	
	&.moderation-overlay-header {
		height: $overlay-header-height;
		border-bottom: 1px solid $text-color-200;
		padding: 3px 10px;
		z-index: 12;
	}
	
	&.moderation-overlay-tab-header {
		height: $overlay-header-height;
		z-index: 11;
	}
}

.ngx-ui-scope {
	&.moderation-content {
		height: calc(100% - #{$header-height});
	}
	
	&.moderation-overlay-content {
		height: calc(100% - #{$overlay-header-height});
	}
	
	&.moderation-overlay-tab-content {
		height: calc(100% - #{$overlay-header-height});
	}
}
//</editor-fold>

.moderator-spacer {
	height: 50px;
	flex-shrink: 0;
	transition: height 200ms;
	
	@media (max-width: $medium-max) {
		height: 30px;
	}
}

//<editor-fold desc="Admin Overlay">
.ngx-overlay.moderator-overlay {
	padding: 40px;
	
	.background {
		background-color: rgba(32, 32, 32, 0.67);
		backdrop-filter: blur(2px);
	}
	
	.overlay-content {
		max-width: 1600px;
		height: 100%;
		width: 100%;
		margin: auto;
		background-color: $foreground;
		border-radius: 4px;
	}
	
	@media (max-width: 600px), (max-height: 600px) {
		padding: 0;
		
		.overlay-content {
			border-radius: 0;
		}
		
		.background {
			display: none;
		}
	}
	
	&.fullscreen {
		padding: 0;
		
		.background {
			display: none;
		}
		
		.overlay-content {
			max-width: unset;
			border-radius: 0;
		}
	}
}
//</editor-fold>

.ngx-overlay.moderator-question {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	
	.background {
		background-color: rgba(32, 32, 32, 0.67);
		backdrop-filter: blur(2px);
	}
	
	.overlay-content {
		max-height: 600px;
		min-height: min(100%, 160px);
		max-width: 600px;
		width: 100%;
		background-color: $foreground;
		border-radius: 4px;
		overflow-y: auto;
		@include default-scroll-bar();
	}
	
	@media (max-width: 600px) {
		padding-left: 0;
		padding-right: 0;
		
		.overlay-content {
			border-radius: 0;
			max-width: unset;
			width: 100%;
		}
	}
	
	@media (max-height: 600px) {
		padding-top: 0;
		padding-bottom: 0;
		
		.overlay-content {
			max-height: unset;
			height: 100%;
		}
	}
}

//<editor-fold desc="Material Overrides">
app-moderator-history-page, app-moderator-combined {
	
	.mat-mdc-tab-header {
		position: sticky;
		top: 80px;
		background-color: $background;
		z-index: 1;
		margin: 0 -16px;
	}
	
	.mat-mdc-tab-label-container {
		background-color: #FCFCFC;
		border-radius: 4px 4px 0 0;
		padding: 0 16px;
	}
	
	.mat-mdc-tab-body-wrapper {
		z-index: 0;
	}
}

app-moderator-messages-create {
	.mat-mdc-select .mat-mdc-select-value {
		margin-right: 10px;
	}
}
//</editor-fold>

//<editor-fold desc="Notification Silos">
.moderator-message-silo, .moderator-response-silo {
	right: 0;
	padding: 16px;
	display: flex;
	flex-flow: column;
	align-items: flex-end;
	width: 100%;
	max-width: 400px;
	transition: top 200ms ease;
	
	.ngx-snackbar-base {
		margin-bottom: 16px;
	}
	
	@media (max-width: $small-max), (max-height: $small-max) {
		padding: 8px;
		
		.ngx-snackbar-base {
			margin-bottom: 8px;
		}
	}
}

.moderator-message-silo {
	top: calc(54px + var(--moderator-header-offset, 0px));
}

.moderator-response-silo {
	top: var(--moderator-header-offset, 0px);
}
//</editor-fold>
