.ngx-is-loading {

	--spinner-size: 20px;
	--spinner-color: currentColor;
	--spinner-thickness: 2px;

	&.loading {
		pointer-events: none;
		opacity: 0.8;
		
		> :not(.ngx-is-loading-spinner) {
			visibility: hidden;
		}

		> .ngx-is-loading-spinner {
			display: block;
		}
	}

	> .ngx-is-loading-spinner {
		display: none;
		box-sizing: border-box;
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		width: var(--spinner-size);
		height: var(--spinner-size);
		margin-top: calc(-1 * (var(--spinner-size) / 2));
		margin-left: calc(-1 * (var(--spinner-size) / 2));
		border-radius: 50%;
		border: var(--spinner-thickness) solid;
		border-color: transparent var(--spinner-color) var(--spinner-color) var(--spinner-color);
		animation: spinner .8s linear infinite;
	}

	@keyframes spinner {
		to {transform: rotate(360deg);}
	}
}
