
$background: var(--ngx-background, #f0f0f0);

$foreground: var(--ngx-foreground, #FCFCFC);
$foreground-900: var(--ngx-foreground-900, #{$foreground});
$foreground-800: var(--ngx-foreground-800, #{$foreground});
$foreground-700: var(--ngx-foreground-700, #{$foreground});
$foreground-600: var(--ngx-foreground-600, #{$foreground});
$foreground-500: $foreground;
$foreground-400: var(--ngx-foreground-400, #{$foreground});
$foreground-300: var(--ngx-foreground-300, #{$foreground});
$foreground-200: var(--ngx-foreground-200, #{$foreground});
$foreground-100: var(--ngx-foreground-100, #{$foreground});

$primary: var(--ngx-primary, #455a64);
$primary-900: var(--ngx-primary-900, #{$primary});
$primary-800: var(--ngx-primary-800, #{$primary});
$primary-700: var(--ngx-primary-700, #{$primary});
$primary-600: var(--ngx-primary-600, #{$primary});
$primary-500: $primary;
$primary-400: var(--ngx-primary-400, #{$primary});
$primary-300: var(--ngx-primary-300, #{$primary});
$primary-200: var(--ngx-primary-200, #{$primary});
$primary-100: var(--ngx-primary-100, #{$primary});

$primary-contrast: var(--ngx-primary-contrast, #FFFFFFDD);
$primary-900-contrast: var(--ngx-primary-900-contrast, #{$primary-contrast});
$primary-800-contrast: var(--ngx-primary-800-contrast, #{$primary-contrast});
$primary-700-contrast: var(--ngx-primary-700-contrast, #{$primary-contrast});
$primary-600-contrast: var(--ngx-primary-600-contrast, #{$primary-contrast});
$primary-500-contrast: $primary-contrast;
$primary-400-contrast: var(--ngx-primary-400-contrast, #{$primary-contrast});
$primary-300-contrast: var(--ngx-primary-300-contrast, #{$primary-contrast});
$primary-200-contrast: var(--ngx-primary-200-contrast, #{$primary-contrast});
$primary-100-contrast: var(--ngx-primary-100-contrast, #{$primary-contrast});

$accent: var(--ngx-accent, #455a64);
$accent-900: var(--ngx-accent-900, #{$accent});
$accent-800: var(--ngx-accent-800, #{$accent});
$accent-700: var(--ngx-accent-700, #{$accent});
$accent-600: var(--ngx-accent-600, #{$accent});
$accent-500: $accent;
$accent-400: var(--ngx-accent-400, #{$accent});
$accent-300: var(--ngx-accent-300, #{$accent});
$accent-200: var(--ngx-accent-200, #{$accent});
$accent-100: var(--ngx-accent-100, #{$accent});

$accent-contrast: var(--ngx-accent-contrast, #FFFFFFDD);
$accent-900-contrast: var(--ngx-accent-900-contrast, #{$accent-contrast});
$accent-800-contrast: var(--ngx-accent-800-contrast, #{$accent-contrast});
$accent-700-contrast: var(--ngx-accent-700-contrast, #{$accent-contrast});
$accent-600-contrast: var(--ngx-accent-600-contrast, #{$accent-contrast});
$accent-500-contrast: $accent-contrast;
$accent-400-contrast: var(--ngx-accent-400-contrast, #{$accent-contrast});
$accent-300-contrast: var(--ngx-accent-300-contrast, #{$accent-contrast});
$accent-200-contrast: var(--ngx-accent-200-contrast, #{$accent-contrast});
$accent-100-contrast: var(--ngx-accent-100-contrast, #{$accent-contrast});

$warn: var(--ngx-warn, #f44336);
$warn-contrast: var(--ngx-warn-contrast, #FFFFFFDD);

$text-color: var(--ngx-text-color, #000000DD);
$text-color-500: $text-color;
$text-color-400: var(--ngx-text-color-400, #{$text-color});
$text-color-300: var(--ngx-text-color-300, #{$text-color});
$text-color-200: var(--ngx-text-color-200, #{$text-color});
$text-color-100: var(--ngx-text-color-100, #{$text-color});

$header: var(--ngx-header, #FFFFFF);
$header-500: $header;
$header-400: var(--ngx-header-400, #{$header});
$header-300: var(--ngx-header-300, #{$header});
$header-200: var(--ngx-header-200, #{$header});
$header-100: var(--ngx-header-100, #{$header});


// Device sizes
$small-max: 600px;
$medium-min: 601px;
$medium-max: 1000px;
$large-min: 1001px;


// Misc Colors
$info: #2595ED;
$success: #3CC899;
$error: #CC0000;
$warning: #FD9803;

// Deprecated
$foreground-light: $foreground-300;
$text-color-light: $text-color-400;
