
.ngx-icon {
  --scale: 1;

  font-size: inherit;
  display: inline-block;
  height: calc(1em + 2 * var(--padding, 0px));
  width: calc(1em + 2 * var(--padding, 0px));
  line-height: 1em;
  text-align: center;
  padding: var(--padding, 0px);
  overflow: hidden;
  box-sizing: border-box;
  user-select: none;
}

ngx-icon {
  &.empty {
    display: none;
  }

  &.inline {
    line-height: inherit;
  }

  &.scaled {
    transform: scale(var(--scale));
  }

  &.font-awesome {
    display: inline-flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    padding: 0;
    width: unset;
    min-width: calc(1em + 2 * var(--padding, 0px));

    &.mat-ripple {
      overflow: hidden;
    }
  }
}
