
.render-html {
    ol, ul {
        list-style-position: outside;
        display: block;
        margin-block-start: 0;
        margin-block-end: 8px;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 32px;
    
        ol, ul {
            margin: 0;
        }
    }
    
    pre {
        display: block;
        padding: 8px;
        margin: 0 0 10px;
        font-family: monospace;
        color: #666;
        line-height: 1.45;
        background-color: #f9f9f9;
        border: 1px solid #e1e1e1;
        border-radius: 2px;
        white-space: pre-wrap !important;
        word-wrap: break-word;
        overflow: visible
    }
    
    ol {
        list-style-type: decimal
    }
    
    ul {
        list-style-type: disc
    }
    
    li {
        display: list-item;
        text-align: -webkit-match-parent;
        margin-bottom: 5px
    }
    
    p {
        display: block;
        margin: 0 0 8px
    }
    
    div {
        display: block;
        margin: 0;
        padding: 0
    }
    
    blockquote {
        display: block;
        font-family: inherit;
        font-size: inherit;
        color: #999;
        margin-block-start: 0;
        margin-block-end: 8px;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding: 0 5px 0 20px;
        border: solid #b1b1b1;
        border-width: 0 0 0 5px
    }
    
    h1 {
        font-size: 2em;
        margin-block-start: .67em;
        margin-block-end: .67em
    }
    
    h1, h2 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700
    }
    
    h2 {
        font-size: 1.5em;
        margin-block-start: .83em;
        margin-block-end: .83em
    }
    
    h3 {
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em
    }
    
    h3, h4 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700
    }
    
    h4 {
        font-size: 1em;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em
    }
    
    h5 {
        font-size: .83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em
    }
    
    h5, h6 {
        display: block;
        margin-inline-start: 0;
        margin-inline-end: 0;
        font-weight: 700
    }
    
    h6 {
        font-size: .67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em
    }
    
    hr {
        display: flex;
        border-width: 1px 0 0;
        border-color: #000;
        border-image: initial;
        height: 1px
    }
}
