
.ngx-form-input {
  display: block;
  margin-bottom: 8px;
  transition: margin-bottom 400ms ease;

  &.hidden {
    margin-bottom: 0;
  }

  > .wrapper {
    display: flex;
    flex-flow: column;
    align-items: stretch;
  }

  .mat-mdc-form-field {
    &.mat-form-field-appearance-outline {
      padding-top: 10px;
    }
  }

  .required {
    color: #CC0000FF;
  }

  .tooltip {
    margin-left: 3px;
    vertical-align: bottom;
    font-size: 1.2em;
    cursor: help;
  }

  .mdc-floating-label {
    z-index: 1;
  }

  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    &, > .fix {
      > ngx-icon {
        --padding: 0.3em;
        font-size: 1.4em;
      }

      > i {
        padding: 0.3em;
        font-size: 1.4em;
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      will-change: color;
    }
  }

  .mat-mdc-form-field-icon-suffix:has(.fix:empty) {
    display: none;
  }

  .fix:empty {
    display: none;
  }

}
