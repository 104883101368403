
ngx-header {
	padding: 10px 20px;
	background-color: #FCFCFC;
	height: 60px;
	z-index: 12;
	
	p.title.title {
		font-size: 28px;
		margin-right: 10px;
	}
}
