@use "@juulsgaard/ngx-tools/styles/reset";
@use "@juulsgaard/ngx-tools";
@use "@juulsgaard/ngx-material";
@use "@juulsgaard/ngx-forms-inputs";

@use "styles/setup";
@use "styles/theme";
@use "styles/material";
@use "styles/directives";
@use "styles/components";
@use "styles/features";

.grow {
	flex-grow: 1;
}
