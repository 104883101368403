
@forward "@juulsgaard/ngx-tools/styles/variables";

$font-stack: Roboto, "Helvetica Neue", sans-serif;

// Custom Colors
$gold: #DFAB23;
$silver: #ACB9D0;
$bronze: #A74813;

$card-gradiant: linear-gradient(251.97deg, #D9E6F8 34.9%, #EEF4FC 100.93%);


@mixin default-scroll-bar() {
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
	
	&::-webkit-scrollbar {
		width: 6px;
		height: 4px;
	}
	
	&::-webkit-scrollbar-thumb {
		background: #00000060;
		border-radius: 2px;
	}
}
