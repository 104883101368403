@use "@juulsgaard/ngx-tools/styles/variables" as *;

.ngx-header {
  display: flex;
  flex-flow: row;
  align-items: center;

  @media (min-width: 601px) {
    > .menu {
      display: none !important;
    }
  }

  > .column {
    flex-shrink: 1;
    min-width: 0;

    .title {
      font-weight: 500;
      font-size: 1em;
      color: $text-color;
    }

    .sub-title {
      margin-top: 0.1em;
      font-size: 0.8em;
      color: $text-color-400;
    }

    .title, .sub-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
    }
  }

  .left-btn {
    font-size: 20px;
    --padding: 10px;
    margin-right: 10px;
    margin-left: -10px;
  }
}

